/*Font 적용*/
@font-face {
  font-family: 'Pretendard-bold';
  src: url('./Pretendard-Bold.subset.woff2') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Pretendard-semibold';
  src: url('./Pretendard-SemiBold.subset.woff2') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Pretendard-medium';
  src: url('./Pretendard-Medium.subset.woff2') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Pretendard-regular';
  src: url('./Pretendard-Regular.subset.woff2') format('woff');
  font-weight: 400;
}
